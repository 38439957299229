<template>
    <!-- Button Save -->
    <div v-if="form.status != 'Approve'">

        <button
            type="button"
            class="btn btn-green me-2"
            :disabled="$store.state.pageLoader || invitationData.invitation_status === 'Invited' || !canEdit"
            v-if="
               (show &&
                   (form.status === 'Draft' || form.status === 'Assigned')) ||
               (data.profile.superAdmin && form.status != 'Approve')
            "
            @click="
                submit(form.status,`${data.profile.name} ${form.id ? 'update' : 'create'} template`)
            "
        >
            {{ translations?.save }}
        </button>
        <!-- Button Save -->
        <!-- Button Reject -->
        <button
            type="button"
            class="btn btn-danger me-2"
            :disabled="$store.state.pageLoader || invitationData.invitation_status === 'Invited' || !canEdit"
            v-if="
                !submitted &&
                show &&
                form.status != 'Draft' &&
                form.status != 'Assigned' &&
                (groups.reviewer.lists.filter((obj) => {
                    return obj.id === data.profile.id;
                }, data.profile).length ||
                groups.approver.lists.filter((obj) => {
                      return obj.id === data.profile.id;
                }, data.profile).length)
            "
            @click="submit('Reject', `${data.profile.name} reject template`)"
        >
        {{ translations?.reject }}
        </button>
        <!-- Button Reject -->
        <!-- Button Review -->
        <button
            type="button"
            class="btn btn-warning me-2"
            :disabled="$store.state.pageLoader || invitationData.invitation_status === 'Invited' || !canEdit"
            v-if="
                show &&
                form.status === 'Assigned' &&
                groups.author.lists.filter((obj) => {
                return obj.id === data.profile.id;
                }, data.profile).length &&
                !data.profile.superAdmin
            "
            @click="
                submit('Submitted', `${data.profile.name} submit template for review`)
            "
        >
        {{ translations?.submit_for_review }}
        </button>
        <!-- Button Review -->
        <!-- Button Approve -->
        <button
            type="button"
            class="btn btn-warning me-2"
            :disabled="$store.state.pageLoader || invitationData.invitation_status === 'Invited' || !canEdit"
            v-if="
                show &&
                form.status === 'Submitted' &&
                groups.reviewer.lists.filter((obj) => {
                    return obj.id === data.profile.id;
                }, data.profile).length
            "
            @click="
                submit('Review', `${data.profile.name} submit template for approval`)
            "
        >
        {{ translations?.submit_for_approve }}
        </button>
        <!-- Button Approve -->
        <!-- Button Approve -->
        <button
            type="button"
            class="btn btn-warning me-2"
            :disabled="$store.state.pageLoader || invitationData.invitation_status === 'Invited' || !canEdit"
            v-if="
                (show &&
                form.status === 'Review' &&
                groups.approver.lists.filter((obj) => {
                    return obj.id === data.profile.id;
                }, data.profile).length) ||
                (form.status != 'Draft' &&
                form.status != 'Approve' &&
                data.profile.superAdmin)
            "
            @click="submit('Approve', `${data.profile.name} approved template`)"
        >
        {{ translations?.approve }}
        </button>
    </div>
    <!-- Button Approve -->
</template>

<script>
import {reactive} from "vue";
import {useStore} from "vuex";
import {computed} from "@vue/reactivity";

export default {
    name: "ButtonGroups",
    props: {
        form: {
            type: Object,
            required: false,
        },
        groups: {
            type: Object,
            required: false,
        },
        show: {
            type: Boolean,
            required: false,
        },
        canEdit: {
            type: Boolean,
            required: false
        },
        submitted: {
            type: Boolean,
            required: false,
            default: false
        },
        translations: {
            type: Object,
            required: false
        }
    },
    emits: ["submitForm"],
    setup(props, {emit}) {
        const store = useStore();

        const invitationData = computed(() => store.getters.getProjectInvitationData);

        let data = reactive({
            profile: store.getters.getProfile,
        });

        const submit = function ($parm, $comment) {
            emit("submitForm", $parm, $comment);
        };

        return {
            props,
            data,
            submit,
            invitationData
        };
    },
};
</script>

<style>
</style>
