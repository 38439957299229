<template>
    <div class="autocomplete-wrapper">
        <div class="input-group search-box">
            <input class="form-control"
                   type="text"
                   :name="name"
                   :placeholder="placeholder"
                   v-model="dataList.search"
                   @keydown.down="navigateDown"
                   @keyup.up="navigateUp"
                   @keydown.enter.prevent="enterSelectedValue"
                   @input="autocomplete"/>

            <button class="btn btn-outline-secondary" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-search" viewBox="0 0 16 16">
                    <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                </svg>
            </button>
        </div>
        <div v-if="dataList.list.length > 0 && dataList.isSearch"
             id="autocompleteList"
             class="dropdown-search-result-wrapper custom-scrollbar">
            <a v-for="(item, index) in dataList.list"
               :key="index"
               class="dropdown-item"
               :class="{'active': index == dataList.activeIndex}"
               href="#"
               @click.prevent="selectedAutocomplete(item)">
                {{ item[showAbleKey] }}
            </a>
        </div>
        <span class="text-danger">{{ errorMessage }}</span>
    </div>
</template>

<script>
import {reactive, watch} from "vue";

export default {
    name: "Autocomplete",
    props: {
        list: {
            type: Array,
            required: false
        },
        name: {
            type: String,
            required: false,
            default: 'search'
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Search'
        },
        showAbleKey: {
            type: String,
            required: false,
            default: 'email'
        },
        errorMessage: {
            type: String,
            required: false
        },
        clear: {
            type: Boolean,
            required: false,
            default: false
        },
        setSearchName: {
            type: String,
            required: false,
            default: ''
        },
    },
    emits: ['selectedAutocomplete', 'autocomplete'],
    setup(props, {emit}) {
        let dataList = reactive({
            list: [],
            search: '',
            isSearch: false,
            activeIndex: -1
        })

        /** watch **/
        watch(
            () => props.list,
            (value) => {
                if (value) {
                    dataList.list = props.list
                }
            },
            {immediate: true}
        );
        watch(
            () => props.clear,
            (value) => {
                if (value){
                    dataList.search = '';
                }
            },
            {immediate: true}
        );
        watch(
            () => props.setSearchName,
            (value) => {
                if (value){
                    dataList.search = value;
                }
            },
            {immediate: true}
        );
        /** watch end**/

        /**Methods**/
        const navigateDown = () => {
            if (dataList.activeIndex < dataList.list.length - 1 && dataList.activeIndex > -2) {
                dataList.activeIndex++;
                let autocompleteList = document.getElementById('autocompleteList');
                let sH = autocompleteList.scrollHeight;
                let aH = autocompleteList.offsetHeight;

                if (sH > aH && dataList.activeIndex > 0) {
                    //need to adjust scroll height
                    let adS = dataList.activeIndex == dataList.list.length - 1 ? (sH - aH) : autocompleteList.scrollTop + (sH - aH) / (dataList.list.length);
                    autocompleteList.scrollTo(0, adS)
                }
            }
        }

        const navigateUp = () => {
            if (dataList.activeIndex >= 1) {
                dataList.activeIndex--;
                let autocompleteList = document.getElementById('autocompleteList');
                let sH = autocompleteList.scrollHeight;
                let aH = autocompleteList.offsetHeight;
                if (sH > aH) {
                    //need to adjust scroll height
                    let adS = dataList.activeIndex == 0 ? 0 : autocompleteList.scrollTop - (sH - aH) / (dataList.list.length);
                    autocompleteList.scrollTo(0, adS)
                }
            }
        }
        const enterSelectedValue = () => {
            let obj = dataList.list[dataList.activeIndex];
            selectedAutocomplete(obj);
            dataList.activeIndex = -1;
        }
        const selectedAutocomplete = (obj) => {
            dataList.search = obj[props.showAbleKey];
            dataList.isSearch = false;
            emit('selectedAutocomplete', obj);
        }
        const autocomplete = () => {
            dataList.isSearch = true;
            emit('autocomplete', dataList.search);
        }
        /**Methods**/

        return {
            dataList,
            navigateDown,
            navigateUp,
            enterSelectedValue,
            autocomplete,
            selectedAutocomplete
        }
    }
}
</script>


<style scoped>
.autocomplete-wrapper {
    position: relative;
}

.dropdown-search-result-wrapper {
    padding: 0.8rem 0;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    z-index: 100;
    background: #fff;
    left: 0;
}

.dropdown-search-result-wrapper .dropdown-item {
    font-size: 95% !important;
    color: #898989;
    padding: 0.5rem 1rem !important;
}

.dropdown-search-result-wrapper .dropdown-item.active {
    color: #ffffff !important;
    background-color: #5c76fa !important;
}

.dropdown-search-result-wrapper .dropdown-item.selected {
    color: #ffffff !important;
    background-color: #5c76fa !important;
}

.dropdown-search-result-wrapper .dropdown-item.selected .check-sign {
    display: block;
}

.dropdown-search-result-wrapper .dropdown-item:hover {
    background-color: #5c76fa !important;
    color: #ffffff !important;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
    background: #5c76fa;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb:hover {
    background-color: #a3b4f9;
}

</style>
