<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12
            col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="border-bottom pb-2 mb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h2>{{ translations?.select_person_to_invite_for }}: <strong>{{ roleName ?? "External Consultant" }}</strong></h2>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="w-100 d-flex mb-3">
                            <input
                                class="d-block w-50 form-control me-2"
                                type="text"
                                @input="searchListUser($event.target.value)"
                                placeholder="Search approved resources"
                            />
                        </div>
                        <div class="w-100 d-flex align-items-center">
                            <div class="w-50 border name-box" id="1st-field">
                                <p
                                    v-for="(user, key) in data.userLists"
                                    :key="`1st-field-user-${key}`"
                                    @click="selectUser(user, key)"
                                    class="name-field py-2 ps-2 border-bottom"
                                >
                                    {{ user.title }}
                                </p>
                            </div>
                            <span class="mx-3">
                                <font-awesome-icon :icon="['fas', 'exchange-alt']"/>
                              </span>
                            <div class="w-50 border name-box" id="2nd-field">
                                <p
                                    v-for="(user, key) in data.assigned"
                                    :key="`2nd-field-user-${key}`"
                                    @click="removeUser(user, key)"
                                    class="name-field py-2 ps-2 border-bottom"
                                >
                                    {{ user.title }}
                                </p>
                            </div>
                        </div>
                        <form class="mt-3 w-100">
                            <div class="row">
                                <div
                                    class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 colsm-12 col-12"
                                >
                                    <div class="mb-3">
                                        <p class="mb-2">{{ translations?.invite_someone_outside_this_group }} </p>
                                        <!--<input class="form-control" type="text" id="search-input"/>-->
                                        <autocomplete :list="data.autocompleteList"
                                                      :clear="data.isEmptyAutocompleteValue"
                                                      @autocomplete="autocomplete"
                                                      @selected-autocomplete="selectedAutocomplete"/>
                                    </div>
                                    <div class="w-100">
                                        <ul class="w-50 search-suggestion-hidden
                                            list-group position-absolute p-3 bg-white"
                                            id="search-suggestion"
                                        ></ul>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 colsm-12 col-12">
                                    <div class="w-100">
                                        <p class="mb-2">
                                            {{ translations?.rational_why_you_need_someone_outside_approved }}
                                           
                                        </p>
                                        <textarea
                                            class="form-control"
                                            v-model="data.rational"
                                            name=""
                                            id=""
                                            cols="4"
                                            rows="4"
                                        ></textarea>
                                        <span class="text-danger">{{ data.rationalErrorMessage }}</span>
                                        <button class="btn btn-primary d-block mt-2"
                                                :disabled="$store.state.pageLoader"
                                                @click.prevent="addToAssignList">
                                                {{ translations?.add_to_list }}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="invite-button-group d-flex justify-content-end mt-4">
                                <button
                                    @click.prevent="closeWindow"
                                    :disabled="$store.state.pageLoader"
                                    type="button"
                                    class="btn btn-secondary me-2">
                                    {{ translations?.cancel }}
                                    
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-primary"
                                    :disabled="$store.state.pageLoader"
                                    @click.prevent="submitForm">
                                    {{ translations?.submit }}
                                    
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {reactive, watch, computed} from "vue";
import HelperFunction from "@/common/helpers";
import Autocomplete from "@/common/components/autocomplete/Autocomplete";
import {useStore} from "vuex";

export default {
    name: "InviteUser",
    components: {Autocomplete},
    props: {
        category: {
            type: String,
            required: false,
        },
        id: {
            type: Number,
            required: false,
        },
        projectId: {
            type: Number,
            required: false,
        },
        companyId: {
            type: Number,
            required: false,
        },
        role: {
            type: String,
            required: false,
        },
        roleName: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
        },
        compName: {
            type: String,
            required: false,
        },
        setUrl: {
            type: String,
            required: false,
        },
        params: {
            type: Object,
            required: false,
        },
    },
    emits: ["approverGroupSuccess"],
    setup(props, {emit}) {
        const {getAllData} = HelperFunction();
        const store = useStore();

        let data = reactive({
            formData: {
                id: "",
                type: "",
                company_id: "",
                role_id: [],
                data: [],
            },
            project_id: "",
            category: "",
            compName: "",
            users: [],
            userLists: [],
            assigned: [],
            setUrl: "",
            params: null,
            autocompleteList: [],
            isEmptyAutocompleteValue: false,
            rational: '',
            rationalErrorMessage: '',
            selectedAutocompleteUser: {
                email: ''
            },
        });
        const translations = computed(() => {
            return store.state.translations;  
        });

        watch(
            () => props,
            (value) => {
                // console.log(value.setUrl, "watch InviteUser");
                data.formData.id = value.id;
                data.formData.company_id = value.companyId;
                data.project_id = value.projectId;
                data.formData.role_id = value.role;
                data.category = value.category;
                data.compName = value.compName;
                data.formData.type = value.type;
                data.setUrl = value.setUrl;
                data.params = value.params;

                if (value.setUrl) getData(value.setUrl, value.params);
            },
            {immediate: true}
        );

        async function getData(url, params) {
            // console.error(url, params, 'URL PRARAMS');
            await getAllData(url, params).then((resp) => {
                const result = resp.data.data;
                // console.error(result)

                if (result.hasOwnProperty('assigned')) {
                    data.users = data.userLists = result.users;
                    data.assigned = result.assigned;
                } else {
                    data.users = data.userLists = result;
                }
            });
        }

        function selectUser(user, index) {
            data.assigned.push(user);
            data.userLists.splice(index, 1);
        }

        function removeUser(user, index) {
            data.userLists.push(user);
            data.assigned.splice(index, 1);
        }

        function searchListUser(value) {
            if (value.length >= 1) {
                data.userLists = data.users.filter((item) =>
                    item.title.toLowerCase().includes(value.toLowerCase())
                );
            } else {
                data.userLists = data.users;
            }
            // let users = dataList.searchUsers.filter((item) => {
            //   let title = item.title.toLowerCase();
            //   return title.includes(value.toLowerCase());
            // });
            // dataList.searchUsers = users;
        }

        function searchSelectedUsers(value) {
            if (value.length < 1) {
                dataList.searchSelectedUsers = dataList.selectedUsers;
                return;
            }
            let users = dataList.searchSelectedUsers.filter((item) => {
                let title = item.title.toLowerCase();
                return title.includes(value.toLowerCase());
            });
            dataList.searchSelectedUsers = users;
        }

        function closeWindow() {
            if (this.category === "project") {
                router.go(-1);
            } else {
                emit("approverGroupSuccess", "cancel");
            }
        }

        const submitForm = function () {
            data.formData.data = data.assigned;
            emit("approverGroupSuccess", data.formData);
        };

        const addToAssignList = () => {
            data.rationalErrorMessage = '';
            if (data.rational === '') {
                data.rationalErrorMessage = 'Rational is required';
                return;
            }
            data.assigned.push({
                ...data.selectedAutocompleteUser,
                rational: data.rational,
                is_assigned: false
            });
            data.isEmptyAutocompleteValue = true;
            data.rational = '';
        }

        //autocomplete
        const autocomplete = (search) => {
            const url = `${vueConfig.User.UsersFilterEndPoint}=${search}`;
            data.autocompleteList = [];
            store.dispatch('getUsersByEmail', {
                'action': url
            }).then(response => {
                data.autocompleteList = response.entries;
            }).catch(error => {
                console.log(error);
            })
        }
        const selectedAutocomplete = (user) => {
            data.isEmptyAutocompleteValue = false;
            data.selectedAutocompleteUser = {
                ...user,
                email: user.email,
                id: user.id,
                rational: user.searchRational ? user.searchRational : null,
                title: user.name,
            };
        }

        return {
            data,
            selectUser,
            removeUser,
            searchListUser,
            searchSelectedUsers,
            closeWindow,
            submitForm,
            selectedAutocomplete,
            autocomplete,
            addToAssignList,
            translations
        };
    },
};
</script>

